import React, { useEffect, useMemo, useRef, useState } from 'react';
import useEvent from 'hooks/useEvent';
import { EventTypes } from 'utils/helpers/eventBus';
import useOnClickOutside from 'hooks/useOnClickOutside';
import classNames from 'classnames';
import useGetOrganizations from 'hooks/api/Organizations/useGetOrganizations';
import { useUserContext } from 'store/UserContext';
import {
  NamespaceType,
  Organization,
  OrganizationApi_fetchAllOrganizations$Path,
} from 'api-client';
import useModal from 'store/ModalStore';
import toggleNamespaceSelector from 'utils/helpers/toggleWorkspaceMenu';
import IconLogout from 'components/Svg/icons/IconLogout';
import useKeyDown from 'hooks/useKeyDown';
import useWorkspaceStore, {
  WorkspaceSelected,
} from 'store/WorkspaceSelectedStore';
import normalizeOrganizationRole from 'utils/helpers/normalizeOrganizationRole';
import InputText from 'components/Form/InputText/InputText';
import IconMagnifyingGlass from 'components/Svg/icons/IconMagnifyingGlass';
import { prepend } from 'ramda';
import history from 'utils/helpers/history';
import { authLinks, settingsLinks } from 'utils/links';
import queryClient from 'utils/helpers/queryClient';
import Avatar from 'components/Avatar';
import Typography from 'components/Typography';
import WorkspaceItem from './WorkspaceItem';
import WorkspaceOption from './WorkspaceOption';
import IconSettings from 'components/Svg/icons/IconSettings';
import IconPlus from 'components/Svg/icons/IconPlus';
import WorkspaceThemeSelector from './WorkspaceThemeSelector';
import MagnifyingGlass from './icons/Search.png';
import './WorkspaceMenu.scss';

type WorkspaceMenuProps = {
  organizations: Organization[];
  isToggled: boolean;
};

interface Workspace {
  type: NamespaceType;
  label: string;
  sublabel: string;
  logo: string | undefined;
  id: string;
  namespace: string;
  name: string;
  email?: string;
}

export const WorkspaceMenu: React.FC<WorkspaceMenuProps> = (props) => {
  const { organizations, isToggled } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState('');

  useOnClickOutside(wrapperRef, () => {
    toggleNamespaceSelector(false);
  });

  const { user } = useUserContext();

  const showCreateOrganizationModal = useModal(
    (store) => store.showCreateOrganizationModal
  );
  const flushModals = useModal((store) => store.flushModals);

  // Hide dialog and show organization modal
  const handleCreateOrganization = () => {
    showCreateOrganizationModal({
      onSuccess: (org: Organization) => {
        setWorkspaceSelected({
          id: org.id,
          namespace: org.name,
          namespaceType: NamespaceType.Organization,
          // Save the indicator that the organization is newly created
          // This is used by organization onboarding component to show the "Congratulations" modal
          state: {
            isNewOrganization: true,
          },
        });
        flushModals();
      },
    });
    toggleNamespaceSelector(false);
  };

  // Hide dialog and logout
  const handleLogoutClick = () => {
    history.push(authLinks.logout());
    toggleNamespaceSelector(false);
  };

  // Hide namespace dialog on esc
  useKeyDown(
    'Escape',
    () => {
      toggleNamespaceSelector(false);
    },
    [toggleNamespaceSelector]
  );
  const workspaceSelected = useWorkspaceStore(
    (state) => state.workspaceSelected
  );
  const setWorkspaceSelected = useWorkspaceStore(
    (state) => state.setWorkspaceSelected
  );

  const handleNamespaceClick = (ws: WorkspaceSelected) => {
    // Clear all query cache on namespace change
    queryClient.clear();
    setWorkspaceSelected(ws);
    toggleNamespaceSelector(false);
  };

  const workspaces: Workspace[] = React.useMemo(() => {
    const userWorkspace: Workspace = {
      type: NamespaceType.User,
      label: 'PRIMARY',
      sublabel: 'ACCOUNT',
      logo: user.logo,
      id: user.id,
      email: user.email,
      namespace: user.username,
      name: user.name || '',
    };

    const orgWorkspaces = organizations.map((org) => ({
      type: NamespaceType.Organization,
      label: 'ORGANIZATION',
      sublabel: normalizeOrganizationRole(org.role),
      logo: org.logo,
      id: org.id,
      namespace: org.name,
      email: undefined,
      name: org.name,
    }));

    return prepend(userWorkspace, orgWorkspaces);
  }, [user.logo, user.id, user.email, user.username, user.name, organizations]);

  const filteredWorkspaces = React.useMemo(() => {
    const filterLowerCased = filter.toLowerCase();

    return workspaces.filter(
      (w) =>
        w.name.toLowerCase().includes(filterLowerCased) ||
        w.namespace.toLowerCase().includes(filterLowerCased)
    );
  }, [filter, workspaces]);

  useEffect(() => {
    if (!isToggled) {
      // Reset filter when menu is hidden
      setFilter('');
    } else {
      // Invalidate org queries when dialog opens
      queryClient.invalidateQueries([
        OrganizationApi_fetchAllOrganizations$Path,
      ]);
    }
  }, [isToggled]);

  return (
    <div
      ref={wrapperRef}
      className="TDB-WorkspaceMenu"
      data-testid="TDB-WorkspaceMenu"
    >
      <div className="TDB-WorkspaceMenu__header">
        <Avatar
          rounded
          namespace={workspaceSelected?.namespace as string}
          logo={workspaceSelected?.logo}
          size="xl"
          className="TDB-WorkspaceMenu__avatar"
        />
        {workspaceSelected?.name && (
          <Typography
            as="p"
            className="mb-half"
            fontSize="body-md"
            fontWeight="600"
          >
            {workspaceSelected.name}
          </Typography>
        )}
        {workspaceSelected?.namespace && (
          <Typography variant="secondary" fontSize="body-sm" fontWeight="500">
            {workspaceSelected.namespace}
          </Typography>
        )}
      </div>
      {organizations.length > 3 && (
        <InputText
          Icon={IconMagnifyingGlass}
          type="search"
          size="small"
          className="TDB-WorkspaceMenu__search"
          placeholder="Search namespace"
          onChange={(e) => setFilter(e.currentTarget.value)}
        />
      )}
      <div
        className={classNames('TDB-WorkspaceMenu__list', {
          'TDB-WorkspaceMenu__list--overflow': filteredWorkspaces.length > 7,
        })}
      >
        <div className="TDB-WorkspaceMenu__inner-list">
          {filteredWorkspaces.map((workspace) => {
            return (
              <WorkspaceItem
                key={workspace.id}
                name={workspace.name}
                namespace={workspace.namespace}
                logo={workspace.logo}
                active={workspaceSelected?.id === workspace.id}
                onClick={() => {
                  handleNamespaceClick({
                    id: workspace.id,
                    name: workspace.name,
                    namespaceType: workspace.type,
                    logo: workspace.logo,
                    namespace: workspace.namespace,
                    email: workspace.email,
                  });
                }}
              />
            );
          })}
        </div>
        {!filteredWorkspaces.length && (
          <div className="TDB-WorkspaceMenu__placeholder">
            <Typography
              fontSize="heading-md"
              fontWeight="700"
              color="text-main-primary"
              align="center"
              as="h5"
              className="mb-2"
            >
              No workspaces found
            </Typography>
            <Typography
              align="center"
              fontSize="body-md"
              color="text-main-primary"
              as="p"
            >{`We can't find any namespace matching "${filter}"`}</Typography>
            <img
              className="TDB-WorkspaceMenu__icon"
              src={MagnifyingGlass}
              alt="Magnifying glass"
            />
          </div>
        )}
      </div>
      <div className="TDB-WorkspaceMenu__main">
        <WorkspaceOption
          label="Settings"
          icon={IconSettings}
          onClick={() => {
            history.push(settingsLinks.root());
            toggleNamespaceSelector(false);
          }}
        />
        <WorkspaceThemeSelector />
      </div>
      <div className="TDB-WorkspaceMenu__footer">
        <WorkspaceOption
          label="Create organization"
          icon={IconPlus}
          onClick={handleCreateOrganization}
        />
        <WorkspaceOption
          label="Logout"
          icon={IconLogout}
          onClick={handleLogoutClick}
        />
      </div>
    </div>
  );
};

const WorkspaceMenuContextProvider: React.FC = () => {
  const [, orgResponse] = useGetOrganizations();
  const isToggled: boolean = useEvent(EventTypes.TOGGLE_WORKSPACE_MENU);

  const organizations = useMemo(() => {
    return orgResponse?.data || [];
  }, [orgResponse]);

  if (!isToggled) {
    return null;
  }

  return (
    <>
      <div className="TDB-WorkspaceMenuLayer" />
      <WorkspaceMenu isToggled={isToggled} organizations={organizations} />
    </>
  );
};

export default WorkspaceMenuContextProvider;
